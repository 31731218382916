<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    @shown="getFilter()"
  >
    <template #button-content>
      <feather-icon
        :badge="total"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          <!--{{$t('label_notifications')}}-->
          <b-avatar
            v-for="item in noteTypes"
            v-b-tooltip.hover.top.d200="$t(item.label)"
            class="mr-50 cursor-pointer"
            :variant="`light-primary`"
            size="30"
          >
            <!--:icon="icon"-->
            <feather-icon
              size="16"
              :icon="item.icon"
              @click.prevent="filterData.type = item.type;"
            />
          </b-avatar>
        </h4>
        <b-badge
          pill
          variant="light-primary"
          class="d-flex align-items-center"
        >
          {{ total }}
        </b-badge>
      </div>
    </li>

    <infinity-list
      url="/user_notifications"
      :refresh-list.sync="refreshList"
      :query-params="{status: 1, type: filterData.type}"
      tagname="li"
      count="10"
      height="200px"
      @data-fetched="total = $event.total"
    >
      <template #item="{item, ind}">
        <b-media
          tag="div"
          no-body
        >
          <b-media-body>
            <b-link
              :key="ind"
              class="note-link d-block px-1 "
              @click="close(); openPage(solveRedirect(item.source_table, item.item_id))"
            >
              <b-media class="">
                <template #aside>
                  <!--:src="item.avatar"-->
                  <!--:text="item.avatar"-->
                  <!--:variant="item.type"-->
                  <b-avatar
                    :variant="`light-primary`"
                    size="35"
                  >
                    <feather-icon
                      size="19"
                      :icon="item.icon"
                    />
                  </b-avatar>
                </template>
                <p class="media-heading">
                  <span class="">
                    {{ item.title }}
                  </span>
                </p>
                <div class="">
                  <small class="item-text ">{{ formatDate(item.postedtime, 'DD.MM.YYYY HH:mm', 'unix') }}</small>
                </div>
              </b-media>
            </b-link>

          </b-media-body>
        </b-media>
      </template>
    </infinity-list>

    <!-- Cart Footer -->

    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="outline-primary"
        block
        @click="readAll();close()"
      >{{ $t('label_mark_all_as_read') }}</b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        :to="{name: 'user_notifications'}"
        variant="primary"
        block
      >{{ $t('label_read_all_notifications') }}</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BMediaBody, VBTooltip
} from 'bootstrap-vue'
import infinityList from '@/views/components/infinityList'
import Ripple from 'vue-ripple-directive'

export default {

    components: {
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BAvatar,
        infinityList,
        BButton,
        BFormCheckbox,
BMediaBody,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            notifications: [],
            refreshList: false,
            noteTypes: [
                        {icon: 'CheckSquareIcon', type: 5, label: 'label_tasks'},
                        {icon: 'UserPlusIcon', type: 1, label: 'label_users'},
                        {icon: 'MailIcon', type: 4, label: 'label_mail'},
                        {icon: 'FileIcon', type: 2, label: 'label_documents'},
                        {icon: 'MessageCircleIcon', type: 3, label: 'label_comments'},
                    ],
            total:0,
            filterData: {type: null}
        }
    },

    watch: {
        filterData: {
            handler: function (newVal, old) {
                this.refreshList = true;
            },
            deep: true
        },
    },
    // created(){
        // this.getNotifications();
    // },
    methods: {
        readAll(){
            this.async('get', '/user_notifications/allread', {}, function (resp) {
                this.refreshList = true;
            });

        },
        solveRedirect(source_table, module_id){

            let params = {};
            let routeName = {};

            switch(source_table){
                case 'com_agrm_deal_tasks': routeName = 'agreement_tabs';params.alias = 'task'; params.id = module_id;  break;
                case 'com_agrm_deal_notes': routeName = 'agreement_tabs';params.alias = 'note'; params.id = module_id; break;
                case 'com_agrm_deal_task_comments': routeName = 'deal_task_details'; params.id = module_id; break;
                case 'com_agreements': routeName = 'deal-details'; params.id = module_id; break;
            }

            return   { name:routeName, params:params};
        },
        getFilter(){

        },
        openPage(router){
            this.$router.push(router).catch(() => {});
        },
        close(){
            setTimeout(() => { document.getElementsByTagName('body')[0].click(); }, 100);
        }
    }

}
</script>

<style>

</style>
